import React from "react"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"

import GameManager from "../components/Trivia2/GameManager"

const questions = [
  {
    questionText: "What Mike's favorite color?",
    choices: [
      { label: "Blue", value: "blue"},
      { label: "Red", value: "red"},
      { label: "Green", value: "green"},
      { label: "Chartreuse", value: "chartreuse"}
    ],
    correctValue: "blue",
    afterSubmitText: "Mike enjoys the color blue. He's not entirely sure what 'chartreuse' is.",

  },

  {
    questionText: "What is Mike's favorite drink?",
    choices: [
      { label: "Coca-Cola", value: "cocacola"},
      { label: "Orange Juice", value: "oj"},
      { label: "Cold Brew Coffee", value: "coldbrew"},
      { label: "Iced Coffee", value: "icedcoffee"}
    ],
    correctValue: "coldbrew",
    afterSubmitText: "Mike enjoys a fine cold brew coffee, which is most definitely not the same as iced coffee.",
  },

  {
    questionText: "What is Mike's favorite movie?",
    choices: [
      { label: "Interstellar", value: "interstellar"},
      { label: "Dark City", value: "darkcity"},
      { label: "Avengers: Endgame", value: "avengers"},
      { label: "High Fidelity", value: "highfidelity"}
    ],
    correctValue: "highfidelity",
    afterSubmitText: "Mike's favorite movie is High Fidelity, though all other choices are in his top ten. Despite it being his favorite movie, he is of the opinion that the High Fidelity television show is even better.",
  },

  {
    questionText: "What existential road trip did Mike take in 2008?",
    choices: [
      { label: "He visited all of the national parks", value: "parks"},
      { label: "He visited all Major League Baseball stadiums", value: "baseball"},
      { label: "He visited a McDonald's in each of the continental states", value: "mcdonalds"},
      { label: "He sought the best hamburger in 23 different state ", value: "hamburglar"}
    ],
    correctValue: "baseball",
    afterSubmitText: "Technically, no answer is completely correct, but the MLB stadium trip is the intended answer. Mike and a friend attempted to see a baseball game at every MLB stadium. They made it to 25 stadiums over 9 weeks before running out of money (twice).",
  },

  {
    questionText: "Why does Mike occasionally wear very strange looking orange glasses?",
    choices: [
      { label: "They help with migraines", value: "migraine"},
      { label: "He's a big Bono fan", value: "bono"},
      { label: "He thinks they look trendy", value: "trendy"},
      { label: "They're made of ruby quartz and prevent uncontrollable laser beams from firing", value: "cyclops"}
    ],
    correctValue: "migraine",
    afterSubmitText: "Nothing fancy or trendy here: the glasses look odd, but they do wonders for migraines.",
  },

]

const Test = props => (
  <Layout>
    <SEO title="Test" />
    <div className="mt-16 mb-12">
      <h1 className="">Test Page</h1>
    </div>

    <div>

        <GameManager questions={questions} />

    </div>

    
  </Layout>
)

export default Test

